import React from 'react';
import { Spin } from 'antd';
import { DevelopmentPageProps } from './DevelopmentPage';

const LazyDevelopmentPage = React.lazy(() => import('./DevelopmentPage'));
export const AsyncDevelopmentPage: React.FC<DevelopmentPageProps> = props => (
	<React.Suspense fallback={<Spin />}>
		<LazyDevelopmentPage {...props} />
	</React.Suspense>
);
