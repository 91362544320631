import React from 'react';
import { AntDConfigProvider } from '@sharefiledev/antd-config';
import { Spin } from 'antd';
import { LocalizationLoader } from '../translate';
import { PaywallBannerProps } from './PaywallBanner';
import { PaywallModalProps } from './PaywallModal';
import { InstallModalProps } from './SolutionInstallModal';

const LazyPaywallBanner = React.lazy(() => import('./PaywallBanner'));
export const AsyncPaywallBanner: React.FC<PaywallBannerProps> = props => (
	<AntDConfigProvider>
		<React.Suspense fallback={<Spin />}>
			<LazyPaywallBanner {...props} />
		</React.Suspense>
	</AntDConfigProvider>
);

const LazyPaywallModal = React.lazy(() => import('./PaywallModal'));
export const AsyncPaywallModal: React.FC<PaywallModalProps> = props => (
	<AntDConfigProvider>
		<React.Suspense fallback={<Spin />}>
			<LazyPaywallModal {...props} />
		</React.Suspense>
	</AntDConfigProvider>
);

const LazySolutionInstallModal = React.lazy(() => import('./SolutionInstallModal'));
export const AsyncSolutionInstallModal: React.FC<InstallModalProps> = props => (
	<AntDConfigProvider>
		<React.Suspense fallback={<Spin />}>
			<LocalizationLoader loadingElement={<Spin />}>
				{() => <LazySolutionInstallModal {...props} />}
			</LocalizationLoader>
		</React.Suspense>
	</AntDConfigProvider>
);
